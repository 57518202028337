<template>
  <section class="pricing-table">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="container text-center pt-5">
              <h4 class="mb-3 mt-5">Start up your Bussiness today</h4>
              <p class="w-75 mx-auto mb-5">Choose a plan that suits you the best. If you are not fully satisfied, we offer 30-day money-back guarantee no questions asked!!</p>
              <div class="row pricing-table">
                <div class="col-md-4 grid-margin stretch-card pricing-card">
                  <div class="card border-primary border pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3>Free</h3>
                      <p>Basic Plan</p>
                      <h1 class="font-weight-normal mb-4">$00.00</h1>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li>Email preview on air</li>
                      <li>Spam testing and blocking</li>
                      <li>10 GB Space</li>
                      <li>50 user accounts</li>
                      <li>Free support for one years</li>
                      <li>Free upgrade for one year</li>
                    </ul>
                    <div class="wrapper">
                      <a href="javascript:void(0);" class="btn btn-outline-primary btn-block">Download</a>
                    </div>
                    <p class="mt-3 mb-0 plan-cost text-gray">Free</p>
                  </div>
                </div>
                <div class="col-md-4 grid-margin stretch-card pricing-card">
                  <div class="card border border-success pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3 class="text-success">Professional</h3>
                      <p>For Business</p>
                      <h1 class="font-weight-normal mb-4">$50.90</h1>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li>Email preview on air</li>
                      <li>Spam testing and blocking</li>
                      <li>50 GB Space</li>
                      <li>100 user accounts</li>
                      <li>Free support for two years</li>
                      <li>Free upgrade for two year</li>
                    </ul>
                    <div class="wrapper">
                      <a href="javascript:void(0);" class="btn btn-success btn-block">Start trial</a>
                    </div>
                    <p class="mt-3 mb-0 plan-cost text-success">or purchase now</p>
                  </div>
                </div>
                <div class="col-md-4 grid-margin stretch-card pricing-card">
                  <div class="card border border-primary pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3>Enterprise</h3>
                      <p>Custom Business</p>
                      <h1 class="font-weight-normal mb-4">$80.90</h1>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li>Email preview on air</li>
                      <li>Spam testing and blocking</li>
                      <li>100 GB Space</li>
                      <li>200 user accounts</li>
                      <li>Free support for two years</li>
                      <li>Free upgrade for two year</li>
                    </ul>
                    <div class="wrapper">
                      <a href="javascript:void(0);" class="btn btn-outline-primary btn-block">Start trial</a>
                    </div>
                    <p class="mt-3 mb-0 plan-cost text-gray">or purchase now</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'pricing-table'
}
</script>